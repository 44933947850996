// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elindulok-js": () => import("./../../../src/pages/elindulok.js" /* webpackChunkName: "component---src-pages-elindulok-js" */),
  "component---src-pages-fejfak-js": () => import("./../../../src/pages/fejfak.js" /* webpackChunkName: "component---src-pages-fejfak-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-irasok-js": () => import("./../../../src/pages/irasok.js" /* webpackChunkName: "component---src-pages-irasok-js" */),
  "component---src-pages-kapcsolat-js": () => import("./../../../src/pages/kapcsolat.js" /* webpackChunkName: "component---src-pages-kapcsolat-js" */),
  "component---src-pages-konyvek-js": () => import("./../../../src/pages/konyvek.js" /* webpackChunkName: "component---src-pages-konyvek-js" */),
  "component---src-pages-labunknyomat-js": () => import("./../../../src/pages/labunknyomat.js" /* webpackChunkName: "component---src-pages-labunknyomat-js" */),
  "component---src-pages-lassucsardas-js": () => import("./../../../src/pages/lassucsardas.js" /* webpackChunkName: "component---src-pages-lassucsardas-js" */),
  "component---src-pages-odusszeuszvaltozatok-js": () => import("./../../../src/pages/odusszeuszvaltozatok.js" /* webpackChunkName: "component---src-pages-odusszeuszvaltozatok-js" */),
  "component---src-pages-pipaspista-js": () => import("./../../../src/pages/pipaspista.js" /* webpackChunkName: "component---src-pages-pipaspista-js" */),
  "component---src-pages-qigong-js": () => import("./../../../src/pages/qigong.js" /* webpackChunkName: "component---src-pages-qigong-js" */),
  "component---src-pages-rolam-js": () => import("./../../../src/pages/rolam.js" /* webpackChunkName: "component---src-pages-rolam-js" */),
  "component---src-pages-romok-js": () => import("./../../../src/pages/romok.js" /* webpackChunkName: "component---src-pages-romok-js" */),
  "component---src-templates-qigong-template-js": () => import("./../../../src/templates/qigongTemplate.js" /* webpackChunkName: "component---src-templates-qigong-template-js" */)
}

